<template>
    <div :class="!invalidite && !succes ? 'mb-6 promotion' : ' promotion'">
        <div class="saisie elevation-2">
            <v-overlay
                :absolute="true"
                opacity="0.4"
                :value="verouille"
            >
                <v-progress-circular
                    v-if="!succes"
                    indeterminate
                    color="#00FFFF"
                ></v-progress-circular>
                <label @click="effacePromotion" class="red btndroit"><v-icon left size="36px" style="color:white" dark>{{ mdiDelete }}</v-icon></label>
            </v-overlay>
            <input type="text" id="recherche" placeholder="Code promotion" v-model="saisie" @keydown.enter="validePromotion">
            <label @click="validePromotion" class="btndroit" for="recherche"><v-icon left size="36px" style="color:white" dark>{{ mdiCheck }}</v-icon></label>
        </div>
        <div v-if="invalidite" class="erreur" v-html="invalidite"></div>
        <div v-if="succes" class="succes" v-html="succes"></div>
    </div>
</template>

<script>
import Api from "../api/api";
import { mdiDelete, mdiCheck } from "@mdi/js";

export default {
    name: "SelecteurPromo",
    data() {
        return {
            urlValiderCodePromo:  process.env.VUE_APP_API_URL + '/promos/valider',
            saisie: "",
            invalidite: "",
            succes: "",
            verouille: false,
            mdiDelete,
            mdiCheck
        }
    },
    props: {
        value: {
            type: Object,
            default: null
        },
        montant: {
            type: Number,
            required: true
        }
    },
    watch: {
        value() {
            this.succes =
                this.value === null ?
                '' :
                'Code promo validé, -' + this.value.valeur + '' + this.value.type + ' appliqué !';
        }
    },
    methods: {
        validePromotion() {
            if (!this.verouille) {
                this.succes = '';
                this.invalidite = '';
                this.verouille = true;
                Api.post(this.urlValiderCodePromo, {
                    code: this.saisie,
                    montant: this.montant
                })
                    .then((res) => {
                        if (res['valide'] === true) {
                            res['code'] = this.saisie;
                            this.$emit('input', res);
                        } else {
                            this.invalidite = res['raison'];
                            this.verouille = false;
                        }
                    }).catch((res) => {
                    this.invalidite = Api.getMessageFrom(res);
                    this.verouille = false;
                })
            }
        },
        effacePromotion() {
            this.promotion = null;
            this.$emit('input', null);
            this.verouille = false;
            this.succes = '';
            this.invalidite = '';
        }
    },
    mounted() {
    }

}
</script>

<style lang="scss" scoped>
    .promotion {
        & > .erreur {
            color:red;
        }
        & > .succes {
            color:#FF00FF;
        }
        & > .succes,.erreur {
            font-weight:bolder;
            text-wrap: revert;
            max-width:360px;
        }
        & > .saisie {
            padding: 0 8px;
            overflow: hidden;
            font-weight: bold;
            color: white;
            border-radius: 10px;
            background-color:white;
            height:44px;
            margin: auto 1%;
            position:relative;

            >input {
                border:0;
                outline:0;
                padding-left:19px;
                margin-top:11px;
                width:100%;
                :focus {
                    outline:none!important;
                }
                &::placeholder {
                    margin:auto;
                    text-transform: capitalize;
                    font: normal normal normal 20px/27px Nunito;
                    color: #D6D6D6;
                }
            }
            .btndroit {
                position:absolute;
                right:0;
                top:0;
                height:100%;
                padding-left:6px;
                background-color:#FF00FF;
                cursor:pointer;
                & > .v-icon{
                    top:4px;
                }
            }
        }
    }
</style>
<style lang="scss">

.saisie > .v-overlay > .v-overlay__content {
    width:100%;
    height:100%;
}
</style>