<template>
    <div class="validation">
        <div class="entete">
            <div class="fil-ariane">
                <router-link to="/">Accueil</router-link>
                <span> > </span>
                <router-link :to="'/panier'">Panier</router-link>
                <span> > </span>
                <router-link :to="'/panier/valider'">Mode de livraison</router-link>
            </div>
            <h1 class="titre">Mode de livraison</h1>
        </div>
        <div class="corps">
            <div class="pave-adresses">
                <div class="carte-arrondie">
                    <h2>Adresse de Facturation et de Livraison</h2>
                    <div class="blocs">
                        <v-card class="bloc livraison" elevation="4">
                            <div>
                                <picture>
                                    <source srcset="@/assets/img/footer/livraison.webp" type="image/webp">
                                    <source srcset="@/assets/img/footer/livraison.png" type="image/jpeg">
                                    <img src="@/assets/img/footer/livraison.png" alt="Saisie concernant la Livraison" decoding="async" width="90" height="67" title="Segment livraison">
                                </picture>
<!--                                <img src="@/assets/img/footer/livraison.png" alt="Saisie concernant la Livraison" decoding="async">-->
                                <h3>Adresse de livraison</h3>
                            </div>
                            <adresse
                                type="livraison"
                                :readonly="!saisieAdresseLivraisonAutorisee || transactionEnCours"
                                :masqueTypeSelecteur="true"
                                @adresse-chargees="adressesLivraisonChargees"
                                v-model="adresseLivraison"
                            ></adresse>
                            <v-checkbox hide-details dense label="Identique pour la facturation" v-model="identiqueFact"></v-checkbox>
                        </v-card>
                        <v-card class="bloc facturation" elevation="4">
                            <div>
                                <picture>
                                    <source srcset="@/assets/img/footer/euro.webp" type="image/webp">
                                    <source srcset="@/assets/img/footer/euro.png" type="image/jpeg">
                                    <img src="@/assets/img/footer/euro.png" alt="Saisie concernant la facturation" decoding="async" width="61" height="67" title="Segment facturation">
                                </picture>
<!--                                <img src="@/assets/img/footer/euro.png"  decoding="async">-->
                                <h3>Adresse de facturation</h3>
                            </div>
                            <adresse
                                v-show="identiqueFact"
                                type="facturation"
                                class="adresse-fixe"
                                :readonly="true"
                                :masque-type-selecteur="true"
                                @adresse-chargees="adressesLivraisonChargees"
                                v-model="adresseFacturation"
                            ></adresse>
                            <adresse
                                v-show="!identiqueFact"
                                type="facturation"
                                :masque-type-selecteur="true"
                                :readonly="!saisieAdresseFacturationAutorisee || transactionEnCours"
                                @adresse-chargees="adressesFacturationChargees"
                                v-model="adresseFacturation"
                            ></adresse>
                        </v-card>
                    </div>
                </div>
            </div>
            <div class="informations">
                <div class="choix-mode carte-arrondie">
                    <div v-if="informationsManquantes.length" class="informations-manquantes">
                        <span>Informations manquantes pour choisir le mode de livraison :</span>
                        <ul>
                            <li v-for="info in informationsManquantes" :key="info">
                                {{ info }}
                            </li>
                        </ul>
                    </div>
                    <v-radio-group v-else v-model="transporteurChoisiId" >
                        <div v-for="transporteur in transporteurs" :key="'transporteur'+ transporteur.id">
                            <v-radio  v-model="transporteur.id" :label="transporteur.libelle + ' - '+ enTTC(transporteur.prix)"></v-radio>
                        </div>
                    </v-radio-group>
                </div>
                <selecteur-promo v-model="promotion" :montant="totalHT"></selecteur-promo>
                <totalise-panier
                    :tarif-transporteur="transporteurChoisi && transporteurChoisi.prix || null"
                    :promotion="promotion"
                ></totalise-panier>
                <btn-theme @click.prevent="sauvegardeCommande" class="btn-action" :disabled="!transporteurChoisi || !etapeCoordonneesValide || transactionEnCours" :loading="transactionEnCours">
                    Confirmer ma commande
                </btn-theme>
            </div>
        </div>
    </div>
</template>
<script>

import Api from '../api/api';
import {mapGetters, mapActions} from "vuex";
import Adresse from '../components/adresse/Adresse.vue';
import MonnaieMixin from '../mixins/monnaie';
import PoidsMixin from '../mixins/poids';
import UserMixin from '../mixins/user';
import ElementMixin from "../mixins/element";
import BtnTheme from "../components/theme/BtnTheme";
import TotalisePanier from "../components/TotalisePanier";
import SelecteurPromo from "../components/SelecteurPromo";

const MARQUES_DEFAUT = [{
    id: null,
    libelle: 'Aucune marque'
}]
export default {
    name: "ValidationPanier",
    mixins: [
        MonnaieMixin,
        PoidsMixin,
        UserMixin,
        ElementMixin
    ],
    components: {
        SelecteurPromo,
        TotalisePanier,
        BtnTheme,
        Adresse
    },
    data: () => ({
        urlModesPossibles:    process.env.VUE_APP_API_URL + '/envoi/modespossibles',
        urlListeMarques:      process.env.VUE_APP_API_URL + '/marque/liste',
        urlCreerCommande:     process.env.VUE_APP_API_URL + '/commande/panier',
        urlInfosCommande:    process.env.VUE_APP_API_URL + '/commande/infos/id',
        urlUploadPieceJointe: process.env.VUE_APP_API_URL + '/commande/importpj',
        adresseLivraison: {},
        adresseFacturation: {},
        identiqueFact: true,
        adressesLivraison: [],
        adressesFacturation: [],
        choixAdresseFacturationDifferente: false,
        chargementAdressesLivraisonTerminee: false,
        chargementAdressesFacturationTerminee: false,

        emballages: "",               // Nombre et type de conteneur (enveloppe, carton...)
        prixemballages: 0,            // Prix de(s) emballage(s)
        conditionnement: 0,           // frais de mise en cartons
        logistique: 0,                // Frais logistique (inclus frais douane)
        transporteurs: [],            // Liste des modes de transports possibles
        promotion: null,
        debounceTransporteurs: null,
        referenceCommande: '',
        marques: Object.assign([], MARQUES_DEFAUT),
        commandePrioritaire: false,
        transporteurChoisiId: null,
        marqueSelectionneeId: null,

        bonCommande: null,
        fichiersAccompagnement: [],
        bonCommandeEnCoursDeTelechargement: false,
        fichierAcommpagnementEnCoursDeTelechargement: false,

        dateDepartSouhaitee: null,
        afficheDatePicker: false,
        afficheDialogueConfirmationCommande: false,

        transactionEnCours: false,
        listeMarquesEnChargement: false,

        tauxTVA: 20,
    }),
    computed: {
        ...mapGetters([
            'user',
            'portail',
            'panier',
            'avoir'
        ]),
        concatenationInformationsModesEnvois() {
            return this.prixTotalPanier+'|'
                +this.poidsTotal+'|'
                +this.adresseLivraison.codepays+'|'
                +this.adresseLivraison.codepostal+'|'
                +this.adresseLivraison.tel1+'|';
        },
        informationsManquantes() {
            let ret = [];

            if (!this.adresseLivraison.codepostal) ret.push('Code postal de livraison');
            if (!this.adresseLivraison.codepays) ret.push('Pays de livraison');
            if (!this.adresseLivraison.tel1) ret.push('Téléphone mobile de livraison');

            return ret;
        },
        prixTotalPanier () {
            return this.arrondiMonetaire(this.panier.reduce((somme, element) => {
                return somme + (element.quantite * this.prixUnitaireCalcule(element));
            }, 0));
        },
        poidsTotal () {
            return this.panier.reduce((somme, element) => {
                return somme + (element.poids * element.quantite);
            }, 0);
        },
        etapeCoordonneesValide () {
            return this.adresseLivraison.valide === 1
                && (this.identiqueFact || this.adresseFacturation.valide === 1)
        },
        etapeValidationValide () {
            return this.transporteurChoisiId !== null
        },
        transporteurValide () {
            return this.transporteurChoisi && Object.keys(this.transporteurChoisi).length > 0
        },
        prixConditionnement () {
            const somme = this.panier.reduce((precedent, item) => {
                let prixref = item.quantite > 1 || item.lot > 1
                    ? isNaN(Number(item.prixref))
                        ? 0
                        : Number(item.prixref)
                    : 0;

                // Note VS: En appliquant Number sur le seul cas où prixref peut ne pas être un nombre,
                // on est sûr d'obtenir un nombre, et donc nul besoin d'avoir la syntaxe "+somme + +prix"
                return precedent + prixref;
            }, 0);

            let miseSurPalette = this.transporteurValide ? this.transporteurChoisi.mise_sur_palette : 0;
            let prixPalettes = this.transporteurValide ? this.transporteurChoisi.prix_palettes : 0;
            return Math.abs(this.arrondiMonetaire(somme + this.conditionnement + miseSurPalette + this.prixemballages + prixPalettes));
        },
        totalHT () {
            let prixTotal = this.prixTotalPanier + this.prixConditionnement + this.logistique;

            // rajouter frais logistique + prix emballage
            if (this.transporteurValide) prixTotal += this.transporteurChoisi.prix;
            return this.arrondiMonetaire(prixTotal);
        },
        avoirDeductibleHT () {
            return !this.avoirTTC ? Math.min(this.totalHT, this.avoir) : 0;
        },
        netCommercial () {
            return this.arrondiMonetaire(this.totalHT - this.avoirDeductibleHT);
        },
        tva () {
            return this.arrondiMonetaire(this.netCommercial * this.tauxTVA / 100)
        },
        totalTTC () {
            return this.arrondiMonetaire(this.netCommercial + this.tva);
        },
        avoirTTC () {
            if (!this.portail) return false;
            return this.portail.avoir_type === 'ttc';
        },
        avoirDeductibleTTC () {
            return this.avoirTTC ? Math.min(this.totalTTC, this.avoir) : 0;
        },
        resteAPayer () {
            return this.arrondiMonetaire(this.totalTTC - this.avoirDeductibleTTC);
        },
        dateDepartSouhaiteeFormatee () {
            if (this.dateDepartSouhaitee === null) return null;
            const [annee, mois, jour] = this.dateDepartSouhaitee.split('-');
            return `${jour}/${mois}/${annee}`;
        },
        transporteurChoisi () {
            if (this.transporteurChoisiId === null) {
                return null;
            }
            return this.transporteurs.find(transporteur => transporteur.id === this.transporteurChoisiId);
        },
        saisieAdresseLivraisonAutorisee() {
            //la case de blocage de saisie d'adresses de livraisons ne concerne pas les admins
            return this.portail.saisie_adresses_livraison
                || this.estAuMoinsClientAdmin(this.user.niveau);
        },
        bypassAdresseLivraison() { //défini si l'adresse de livraison est préselectionnée automatiquement (et peut etre bypassée)
            return !this.saisieAdresseLivraisonAutorisee && (this.adressesLivraison && this.adressesLivraison.length === 1);
        },
        saisieAdresseFacturationAutorisee() {
            //la case inclus la prise en compte d'adresse mémorisé dans portail et donc s'applique aussi aux admins client
            return this.portail.saisie_adresses_facturation;
        },
        bypassAdresseFacturation() {
            //si la saisie de l'adrese n'est pas autorisé et qu'elle est prédéfinie ou recopiée de la livraison, le bypass est autorisé
            return !this.saisieAdresseFacturationAutorisee
                && ((this.adressesFacturation && this.adressesFacturation.length === 1)
                    || (this.bypassAdresseLivraison && !this.choixAdresseFacturationDifferente));
        },
        afficheAdresseFacturation () {
            //dans tous les cas, si l'adresse est mémorisée dans la table portail, elle est prioritaire et ne peut être supplantée
            if (this.portail.adresse_facturation_predefinie) return false;

            //Si plus d'une adresse de facturation est mémorisée, dans ce cas, on doit obligatoirement choisir quel adresse on veut.
            if (this.adressesFacturation.length > 1 ) return true;

            // affiche l'adresse si on a le droit de saisie et que la case de choix d'adresse différente est cochée (toujours vrai en B2B)
            return this.saisieAdresseFacturationAutorisee && this.choixAdresseFacturationDifferente ;
        },
        doitPasserLivraison () { //si ceci est vrai, la selection d'adresse sera bypassée, et on arrivera directement sur étape du mode de livraison
            return this.etapeCoordonneesValide
                && this.bypassAdresseFacturation
                && this.bypassAdresseLivraison;
        },

        delaiMinimumAvantLivraison () {
            let delaiMinimum = 0;
            for (let i = 0; i < this.panier.length; i++) {
                let element = this.panier[i];
                if (!this.comportePrixAPalier(element)) continue;

                let palier = this.palierAtteint(element, Math.max(this.quantiteMinimaleCalculee(element), element.quantite));
                if (!palier.delai) continue;
                if (palier.delai > delaiMinimum) delaiMinimum = palier.delai;

                // Inutile, puisque le mixin element contient tout ce qu'il faut
                // let prixPalier = element.prix_palier;
                // for (let j = 0; j < prixPalier.length; j++) {
                //     let palier = prixPalier[j],
                //         palierSuivant = prixPalier[j+1],
                //         palierEligible = element.quantite >= palier.quantite && (palierSuivant ? element.quantite < palierSuivant.quantite : true);
                //
                //     if (!Object.hasOwnProperty.call(palier, 'delai') || !palierEligible) continue;
                //
                //     if (palier.delai > delaiMinimum) delaiMinimum = palier.delai;
                // }
            }
            return delaiMinimum;
        }
    },
    watch:{
        concatenationInformationsModesEnvois() {
            this.recupererTransporteurs();
            this.transporteurChoisiId = null;
        },
        adresseLivraison: {
            handler () {
                //si la saisie d'adresse de facturation est autorisée mais qu'elle est choisie identique, on recopie l'adresse de livraison
                if (!this.choixAdresseFacturationDifferente && this.identiqueFact) {
                   this.$set(this, 'adresseFacturation', Object.assign({}, this.adresseLivraison));
                   delete this.adresseFacturation.intitule;
                }
             },
             deep: true
        },
        identiqueFact: {
            handler() {
                if(this.identiqueFact) {
                    this.$set(this, 'adresseFacturation', Object.assign({}, this.adresseLivraison));
                    delete this.adresseFacturation.intitule;
                }
            }
        },
        etapeCoordonneesValide: {
            handler() {
                if (this.etapeCoordonneesValide)
                    this.recupererTransporteurs();
            }
        }
    },
    methods: {
        ...mapActions([
            'getSoldeAvoir',
            'videPanier',
        ]),
        copieVersFacturation () {
            this.adresseFacturation = Object.assign({}, this.adresseLivraison);
        },
        copieVersLivraison () {
            this.adresseLivraison = Object.assign({}, this.adresseFacturation);
        },
        redirigeVersPanier () {
            this.$router.push({ path: '/panier' });
        },
        cacheDatePicker () {
            this.afficheDatePicker = false;
        },
        datesAutorisees (date) {
            const [annee, mois, jour] = date.split('-');
            let aujourdhui = new Date(),
                dateObject = new Date(annee, mois - 1, jour);
            if ((Object.hasOwnProperty.call(this.portail, 'delai_expedition') && this.portail.delai_expedition > 0) || this.delaiMinimumAvantLivraison > 0) {
                // calcul nouvelle date;
                aujourdhui = this.ajouteJoursOuvres(aujourdhui, Math.max(this.portail.delai_expedition, this.delaiMinimumAvantLivraison));
            }

            // On retire 1 au mois, car le datepicker considère Jan = 1
            // alors que la norme considère Jan = 0
            // Ça explique aussi la ligne suivante, Date.prototype.getDay
            // retourne un nombre entre 0 et 6 (0 = Dim, 6 = Sam)
            // référence: ECMA-262, 7th edition (2016), sections 20.3.1.4 et 20.3.1.6
            if ([6,0].includes(dateObject.getDay())) {
                return false;
            }

            let msDiff = dateObject.getTime() - aujourdhui.getTime();
            let jourDiff = Math.ceil(msDiff / (1_000 * 60 * 60 * 24));

            return jourDiff > 0;
        },
        adressesDifferentes () { //determine si les adresses sont différentes
            return JSON.stringify(this.adresseFacturation) !== JSON.stringify(this.adresseLivraison);
        },
        ajouteJoursOuvres (date, joursOuvres) {
            const ajd = new Date();
            let y = date.getFullYear(),
                m = date.getMonth(),
                d = date.getDate();

            if (ajd.getHours() > 12 || ajd.getHours() === 12 && ajd.getMinutes() > 15) joursOuvres++;

            while (joursOuvres) {
                d++;
                if (d === 32) {
                    m++;
                    d = 0;
                }
                if (m === 12) {
                    y++;
                    m = 0;
                }
                if (this.estJourOuvre) joursOuvres--;
            }

            return new Date(y, m, d);
        },
        estJourOuvre (date) {
            return [6,0].includes(date.getDay());
        },
        effaceDateDepartSouhaitee () {
            this.dateDepartSouhaitee = null;
        },
        adressesLivraisonChargees (adresses) {
            this.adressesLivraison = adresses;
            this.chargementAdressesLivraisonTerminee = true
            //this.passeEtape2SiNecessaire();
        },
        adressesFacturationChargees (adresses) {
            this.adressesFacturation = adresses;
            this.chargementAdressesFacturationTerminee = true
            //this.passeEtape2SiNecessaire();
        },
        //passeEtape2SiNecessaire () {
        //    if (this.doitPasserLivraison) this.etapeSuivante();
        //},
        recupererTransporteurs() {
            let data = new FormData;

            data.append('soustotal', this.prixTotalPanier);
            data.append('poids', this.poidsTotal);
            data.append('epaisseur', '0');
            data.append('societe', this.adresseLivraison.societe);
            data.append('codepays', this.adresseLivraison.codepays);
            data.append('codepostal', this.adresseLivraison.codepostal);
            data.append('email', this.adresseLivraison.email);
            data.append('telephone', this.adresseLivraison.tel1);

            clearTimeout(this.debounceTransporteurs)

            // delay new call 500ms
            this.debounceTransporteurs = setTimeout(() => {
                if (this.informationsManquantes.length === 0)
                return Api.post(this.urlModesPossibles, data)
                    .then(retour => {  //Note de DF: cette partie existait déjà, inutile de la réinventer
                        this.transporteurs      = retour.modes;
                        this.emballages         = retour.emballages;        // Nombre et type de conteneur (enveloppe, carton...)
                        this.prixemballages     = retour.prixemballages;    // Prix de(s) emballage(s)
                        this.conditionnement    = retour.conditionnement;   // frais de mise en cartons
                        this.logistique         = retour.logistique;        // Frais logistique (inclus frais douane)
                        if (!this.transporteurs.includes(this.transporteurChoisi)) this.transporteurChoisiId = null;
                        if (this.transporteurs.length === 0) {
                            this.$store.commit('addSnackMessage', {msg: "Aucun mode d'envoi disponible pour cette destination. Veuillez contacter notre support."});
                        } else {
                            if (this.transporteurs.length === 1) this.transporteurChoisiId = this.transporteurs[0].id;
                            //this.phase = PHASES.VALIDATION;
                        }
                        this.transactionEnCours = false;
                    }).catch(err => {
                        this.transporteurs = [];
                        const message = Api.getMessageFrom(err);
                        if (message)
                            this.$store.commit('addSnackMessage', {msg: message});
                        this.transactionEnCours = false;
                    });
            }, 500);
        },
        recupereListeMarques () {
            this.listeMarquesEnChargement = true;

            Api.get(this.urlListeMarques)
                .then(res => {
                    let marques = Object.assign([], MARQUES_DEFAUT);
                    for  (let i = 0; i < res.length; i++) {
                        marques.push(res[i]);
                    }
                    this.marques = marques;
                })
                .catch(err => {
                    this.$store.commit('addSnackMessage', {msg: err});
                })
                .finally(() => {
                    this.listeMarquesEnChargement = false;
                })
        },
        ajouteAdresseFormData (objetAdresse, formData, prefixe = '') {
            let clefs = Object.keys(objetAdresse),
                len = clefs.length;

            for (let i = 0; i < len; i++) {
                let champ = objetAdresse[clefs[i]];
                if (typeof champ !== 'undefined' && champ !== null) {
                    formData.append(prefixe + clefs[i], champ);
                }
            }
        },
        cacheDialogueConfirmationCommande () {
            this.afficheDialogueConfirmationCommande = false;
        },
        sauvegardeCommande () {
            this.cacheDialogueConfirmationCommande();
            this.transactionEnCours = true;

            let data = new FormData();

            if (this.transporteurValide && Object.hasOwnProperty.call(this.transporteurChoisi, 'mode')) {
                data.append('livraison_mode', this.transporteurChoisi.mode);
            }
            if (this.dateDepartSouhaitee !== null) {
                data.append('datesouhaite', this.dateDepartSouhaitee);
            }
            let now = new Date();
            data.append('reference', this.referenceCommande.length > 0 ? this.referenceCommande : 'CMD'+ now.toISOString().replaceAll('-','').replaceAll(':','').replaceAll('T','-').split('.')[0]);

            this.ajouteAdresseFormData(this.adresseLivraison, data, 'livraison_');

            this.ajouteAdresseFormData(this.adresseFacturation, data, 'facturation_');

            if (this.marqueSelectionneeId !== null) {
                data.append('marque_id', this.marqueSelectionneeId);
            }

            if (this.bonCommande !== null) {
                let bctmp = [],
                    nombc = [];
                bctmp.push(this.bonCommande.fichiertmp);
                nombc.push(this.bonCommande.nomfichier);

                data.append('bctmp', bctmp);
                data.append('nombc', nombc);
            }

            if (this.fichiersAccompagnement.length > 0) {
                let fichierstmp  = [],
                    nomsfichiers = [];
                for (let i = 0; i < this.fichiersAccompagnement.length; i++) {
                    let fichier = this.fichiersAccompagnement[i];
                    fichierstmp.push(fichier.fichiertmp);
                    nomsfichiers.push(fichier.nomfichier);
                }
                data.append('fichiertmp', fichierstmp);
                data.append('nomfichier', nomsfichiers);
            }

            data.append('panier', JSON.stringify(this.panier));
            data.append('prioritaire', '0');

            if (this.promotion)
                data.append('codepromo', this.promotion.code)

            this.transactionEnCours = false;
            Api.post(this.urlCreerCommande, data)
                .then(commande_id => {
                    this.getSoldeAvoir();
                    this.videPanier();
                    this.$router.push({path: '/commande/' + commande_id });
                })
                .catch(() => {
                    this.$store.commit('addSnackMessage', {msg: "Une erreur est survenue durant la validation de votre commande.\nMerci de réessayer plus tard ou de contacter le service technique."});
                }).finally(() => {
                this.transactionEnCours = false;
            });
        }
    },
    mounted() {
        //obligatoire en B2B
        if (this.portail.type === 'B2B') this.choixAdresseFacturationDifferente = true;

        this.getSoldeAvoir();
    }
}
</script>
<style scoped lang="scss">
.validation {
    flex-direction: column;
    width: 100%;

    display:flex;
    & .corps {
        & .pave-adresses {
            max-width:865px;
            min-width:724px;
            & h2 {
                text-align:center;
                font: normal normal bold 30px/41px Nunito;
            }
            & .carte-arrondie {
                padding: 10px 14px 25px;
                & > h2 {
                    margin-bottom:10px;
                }
                & .blocs {
                    display:flex;
                    flex-direction: row;
                    justify-content: space-around;
                    & .bloc {
                        background-color:#F5F5F5;
                        padding:6px;
                        width:312px;
                        & div {
                            text-align: center;
                            & h3 {
                                display:inline-block;
                                text-align:center;
                                vertical-align: top;
                                font: normal normal 600 20px/27px Nunito;
                            }
                            & img {
                                margin-right:8px;
                                max-height:26px;
                            }
                        }
                        &.livraison {

                        }
                        &.facturation {
                            & .adresse-fixe {
                                margin-top:52px;
                            }
                        }
                    }
                }
            }
        }
        & .informations {
            display:flex;
            flex-direction: column;
            width:auto;
            margin:auto;
            & > *:not(:first-child,.recap) {
                margin-top:20px;
            }
            & > .recap {
                margin-top:12px;
            }
            & .choix-mode {
                border-radius:16px;
                margin-bottom:0;
                padding: 0 16px;
                max-width:385px;
                min-width:300px;
                & > .v-input {
                }
                & .informations-manquantes {
                    & li {
                        font-weight:bolder;
                    }
                    color:red;
                    padding:12px;
                }
            }
        }
    }






    & .validation-titre {
        text-align: center;
        font-size: 1.2em;
        font-weight: 500;
        padding: 12px;
    }
    & .validation-actions {
        padding: 12px;
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 auto;
        & .phase {
            flex-grow: 1;
        }
        & .btn-action {
            width: 250px;
        }
    }
    & .validation-body {
        padding: 20px;
        padding-top: 0;
        & .pave-adresse {
            display: flex;
            flex-direction: column;
            align-items: center;
            & .panneau-erreur {
                margin: 12px;
                padding: 40px;
                border: 1px solid #cccccc;
            }
        }
        & .controle-adresse {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: center;
            & .check-adresse-differente {
                margin-right: 12px ;
            }
        }
        & .validation-parametres {
            > .row {
                > .col {
                    padding-top: 2px;
                    padding-bottom: 2px;
                }
            }
        }
        & .validation-details {

        }
    }
}
.obligatoire {
    &.information {
        color: #ff4136;
    }
    & label::after {
        content: '*';
        color: #ff4136;
        font-weight:bold;
        margin-left: 0.5em;
        vertical-align: text-bottom;
    }
}
.total-gras {
    font-size: 1.2em;
    font-weight: 700 !important;
}
@media (max-width: 1020px) {
    .texte-phase {
        display: none;
    }
}
</style>